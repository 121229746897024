import React from 'react';

import { Link } from 'gatsby';
import Footer from './footerComponent';
import menuStyles from './menu.module.css';

export default () => (
  <div className={menuStyles.container}>
    <div className={menuStyles.menuHolder}>
      <div className={menuStyles.logoContainer}>
        <Link className={menuStyles.logo} to="/">WOW</Link>
        <div className={menuStyles.headerText}>
          <div className={menuStyles.logoDescription}>Interior Design</div>
          <div className={menuStyles.logoStorage}>Creative Storage Solutions</div>
        </div>
      </div>

      <Link className={menuStyles.bannerBtn} to="/contact/design-visit">
        {'Book a free design visit >'}
      </Link>

      <div className={menuStyles.links}>
        <Link className={menuStyles.link} activeClassName={menuStyles.linkActive} partiallyActive to="/portfolio/">Previous Work</Link>
        <Link className={menuStyles.link} activeClassName={menuStyles.linkActive} partiallyActive to="/process/">Our Process</Link>
        <Link className={menuStyles.link} activeClassName={menuStyles.linkActive} partiallyActive to="/articles/">Articles</Link>
        <Link className={menuStyles.link} activeClassName={menuStyles.linkActive} partiallyActive to="/contact/">Contact</Link>
        <Link className={menuStyles.link} activeClassName={menuStyles.linkActive} partiallyActive to="/about/">About</Link>
      </div>

      <Footer />
    </div>
  </div>
);
