/* eslint-disable react/require-default-props */

import React from 'react';
import PropTypes from 'prop-types';

import layoutStyles from './layout.module.css';
import 'fontsource-raleway/300-normal.css';
import 'fontsource-raleway/400-normal.css';
import 'fontsource-caveat/400-normal.css';
import 'fontsource-noto-sans-tc/300-normal.css';
import 'fontsource-noto-sans-tc/400-normal.css';
import 'fontsource-noto-sans-tc/700-normal.css';
import 'fontsource-spinnaker/400-normal.css';

import Seo from './seo';
import Header from './header';
import Footer from './footerComponent';
import BannerContainer from './bannerContainer';

const Layout = ({ children, title, description }) => (
  <div>
    <Seo title={title} description={description} />
    <Header />
    <div className={layoutStyles.layoutContainer}>
      {children}
    </div>
    <div className={layoutStyles.layoutFooterContainer}>
      <Footer />
    </div>
    <BannerContainer />
  </div>
);

export default Layout;

Layout.propTypes = {
  children: PropTypes.array.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};
