import React from 'react';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { Link } from 'gatsby';

import {
  FaPhoneSquare, FaEnvelopeSquare, FaPinterest, FaTwitter,
} from 'react-icons/fa';

import footerStyles from './footer.module.css';

export default () => (
  <div className={footerStyles.footerContainer}>
    <div className={footerStyles.footerLink}>
      <a href="mailto:info@wowinteriordesign.com" title="email">
        <span className={`${footerStyles.footerIcon}`}><FaEnvelopeSquare /></span>
        info@wowinteriordesign.com
      </a>
    </div>
    <div className={footerStyles.footerLink}>
      <a href="tel:01303847480" title="phone">
        <span className={`${footerStyles.footerIcon}`}><FaPhoneSquare /></span>
        01303 847480
      </a>
    </div>
    <div className={footerStyles.footerLink}>
      <OutboundLink href="https://twitter.com/InteriorsbyWow" title="twitter">
        <span className={`${footerStyles.footerIcon} ${footerStyles.footerIconStandard} ${footerStyles.twitter}`}><FaTwitter /></span>
      </OutboundLink>
    </div>
    <div className={footerStyles.footerLink}>
      <OutboundLink href="https://uk.pinterest.com/wowinteriordes" title="pinterest">
        <span className={`${footerStyles.footerIcon} ${footerStyles.pinterest}`}><FaPinterest /></span>
      </OutboundLink>
    </div>
    <div className={footerStyles.footerContact}>
      <span>Wow Interior Design</span>
      <span>Suite 4</span>
      <span>Mottis Business Centre</span>
      <span>Cheriton High Street</span>
      <span>Folkestone</span>
      <span>Kent</span>
      <span>CT19 4QJ</span>
    </div>
    <div className={footerStyles.footerLink}>
      <div className={footerStyles.footerPrivacyLink}>
        <Link to="/coronavirus-covid-19">
          covid-19 update
        </Link>
        <Link to="/privacy-policy">
          view privacy policy
        </Link>
      </div>
    </div>
  </div>
);
