import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Link } from 'gatsby';

import consentBannerStyles from './consentBanner.module.css';

class ConsentBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleVisible = this.toggleVisible.bind(this);
    this.accept = this.accept.bind(this);
  }

  componentDidMount() {
    if (this.getCookieValue() === undefined) {
      if (window.location.pathname === '/') {
        setTimeout(this.toggleVisible.bind(null, true), 4000);
      } else {
        this.toggleVisible(true);
      }
    }
  }

  getCookieValue() {
    const { cookieName } = this.props;
    return Cookies.get(cookieName);
  }

  setCookie(cookieName, cookieValue) {
    const { expires } = this.props;
    const cookieSecurity = window.location.protocol === 'https:';

    const cookieOptions = {
      expires, secure: cookieSecurity,
    };

    Cookies.set(cookieName, cookieValue, cookieOptions);
  }

  toggleVisible(value) {
    this.setState({ visible: value });
  }

  accept() {
    const { cookieName, cookieValue } = this.props;
    this.setCookie(cookieName, cookieValue);
    this.setState({ visible: false });
  }

  render() {
    const { visible } = this.state;
    if (!visible || ['/privacy-policy/', '/coronavirus-covid-19/', '/404/', '/privacy-policy', '/coronavirus-covid-19', '/404'].includes(window.location.pathname)) {
      return null;
    }

    return (
      <div className={consentBannerStyles.consentBannerContainer}>
        <div className={consentBannerStyles.consentBannerText}>
          We use cookies to improve the user experience on our website.
          By continuing to use our website we will assume you are happy to receive cookies from us.
          For more information please view our
          {' '}
          <Link className={consentBannerStyles.consentBannerPrivacyLink} to="/privacy-policy">
            privacy policy.
          </Link>
        </div>
        <button className={consentBannerStyles.consentBannerButton} type="button" onClick={this.accept}>
          Accept
        </button>
      </div>
    );
  }
}

export default ConsentBanner;

ConsentBanner.propTypes = {
  expires: PropTypes.number,
  cookieName: PropTypes.string,
  cookieValue: PropTypes.bool,
};

ConsentBanner.defaultProps = {
  expires: 365,
  cookieName: 'CookieConsent',
  cookieValue: true,
};
