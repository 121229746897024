import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import logoStyles from './logo.module.css';

const Logo = (props) => {
  const { scrolled } = props;

  return (
    <div className={logoStyles.headerLogo}>
      <Link className={`${logoStyles.logo} ${scrolled && logoStyles.scrolledLogo}`} to="/">WOW</Link>
      <div className={`${logoStyles.headerText} ${scrolled && logoStyles.scrolledHeaderText}`}>
        <div className={`${logoStyles.logoDescription} ${scrolled && logoStyles.scrolledLogoDescription}`}>
          Interior Design
        </div>
        <div className={`${logoStyles.logoStorage} ${scrolled && logoStyles.scrolledLogoStorage}`}>
          Creative Storage Solutions
        </div>
      </div>
      <div className={scrolled ? logoStyles.scrolledSlog : logoStyles.slog}>
        creative storage solutions
      </div>
    </div>
  );
};

export default Logo;

Logo.propTypes = {
  scrolled: PropTypes.bool.isRequired,
};
