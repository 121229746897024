import React from 'react';

import ConsentBanner from './consentBanner';
import CovidBanner from './covidBanner';

import bannerStyles from './bannerContainer.module.css';

export default () => (
  <div className={bannerStyles.bannerContainer}>
    <ConsentBanner />
    <CovidBanner />
  </div>
);
