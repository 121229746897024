/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Link } from 'gatsby';

import covidBannerStyles from './covidBanner.module.css';

class CovidBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.toggleVisible = this.toggleVisible.bind(this);
    this.accept = this.accept.bind(this);
  }

  componentDidMount() {
    if (this.getCookieValue() === undefined) {
      if (window.location.pathname === '/') {
        setTimeout(this.toggleVisible.bind(null, true), 5000);
      } else {
        this.toggleVisible(true);
      }
    }
  }

  getCookieValue() {
    const { cookieName } = this.props;
    return Cookies.get(cookieName);
  }

  setCookie(cookieName, cookieValue) {
    const { expires } = this.props;
    const cookieSecurity = window.location.protocol === 'https:';

    const cookieOptions = {
      expires, secure: cookieSecurity,
    };

    Cookies.set(cookieName, cookieValue, cookieOptions);
  }

  toggleVisible(value) {
    this.setState({ visible: value });
  }

  accept() {
    const { cookieName, cookieValue } = this.props;
    this.setCookie(cookieName, cookieValue);
    this.setState({ visible: false });
  }

  render() {
    const { visible } = this.state;
    if (!visible || ['/privacy-policy/', '/coronavirus-covid-19/', '/404/', '/privacy-policy', '/coronavirus-covid-19', '/404'].includes(window.location.pathname)) {
      return null;
    }

    return (
      <div className={covidBannerStyles.covidBannerContainer}>
        <div className={covidBannerStyles.covidBannerText}>
          <Link className={covidBannerStyles.covidBannerPrivacyLink} to="/coronavirus-covid-19">
            Covid-19 update:
          </Link>
          we are still open for business as usual and will continue to provide our products to our customers with the sales office remaining open
        </div>
        <button className={covidBannerStyles.covidBannerButton} type="button" onClick={this.accept}>
          Ok
        </button>
      </div>
    );
  }
}

export default CovidBanner;

CovidBanner.propTypes = {
  expires: PropTypes.number,
  cookieName: PropTypes.string,
  cookieValue: PropTypes.bool,
};

CovidBanner.defaultProps = {
  expires: 365,
  cookieName: 'CookieCovid',
  cookieValue: true,
};
