import React from 'react';

import { Link } from 'gatsby';

import Logo from './logoComponent';

import headerStyles from './header.module.css';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
      showMenu: false,
      hideMenuCss: '',
    };

    this.toggleScroll = this.toggleScroll.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.toggleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleScroll);
  }

  toggleScroll() {
    const triggerPoint = Math.round(window.innerHeight / 6);
    if (window.scrollY > triggerPoint) {
      this.setState({ scrolled: true });
      return;
    }

    this.setState({ scrolled: false });
  }

  toggleMenu() {
    const { showMenu } = this.state;
    this.setState({
      showMenu: !showMenu,
      hideMenuCss: 'linksHide',
    });
  }

  render() {
    const { scrolled, showMenu, hideMenuCss } = this.state;

    return (
      <header className={scrolled ? headerStyles.scrolledHeader : null}>
        <div className={headerStyles.headerContainer}>
          <Logo scrolled={scrolled} />
          <div className={scrolled
            ? headerStyles.scrolledBannerContainer : headerStyles.bannerContainer}
          >
            <Link className={headerStyles.bannerBtn} to="/contact/design-visit">
              {'Book a free design visit >'}
            </Link>
          </div>
          <button className={headerStyles.hamburgerContainer} onClick={this.toggleMenu} aria-label="Show Hide Menu" type="button">
            <div className={`${headerStyles.hamburgerIcon} ${scrolled && headerStyles.scrolledHamburgerIcon}`}>&#9776;</div>
            <div className={`${headerStyles.hamburgerText} ${scrolled && headerStyles.scrolledHamburgerText}`}>menu</div>
          </button>
        </div>
        <div className={headerStyles.mobileBannerContainer}>
          <Link className={headerStyles.bannerBtn} to="/contact/design-visit">
            {'Book a free design visit >'}
          </Link>
        </div>
        <div className={`${headerStyles.links} ${showMenu ? headerStyles.linksDisplay : headerStyles[hideMenuCss]}`}>
          <Link to="/portfolio" activeClassName={headerStyles.headerLinkActive} partiallyActive>Previous Work</Link>
          <Link to="/process" activeClassName={headerStyles.headerLinkActive} partiallyActive>Our Process</Link>
          <Link to="/articles" activeClassName={headerStyles.headerLinkActive} partiallyActive>Articles</Link>
          <Link to="/contact" activeClassName={headerStyles.headerLinkActive} partiallyActive>Contact</Link>
          <Link to="/about" activeClassName={headerStyles.headerLinkActive} partiallyActive>About</Link>
        </div>
      </header>
    );
  }
}

export default Header;
